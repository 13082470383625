<template>
	<div class="inquiry-card">
		<h4>문의 접수가 완료되었습니다.</h4>
		<p>
			영업일 기준 1~4일 내에 작성해주신 이메일로 답변될 예정입니다.
			<br />감사합니다.
		</p>
		<div class="button-group">
			<router-link to="/"
				><button class="btn--md btn--fill-accent" href="/">
					홈으로
				</button></router-link
			>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style></style>
